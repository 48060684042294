body {
    margin: 0;
}
p,
h1,
h2,
h3,
h4,
h5,
h6 {
    margin: 0;
    padding: 0;
}
@font-face {
    font-family: "Geomanist";
    src: url("../assets/fonts/Geomanist-Light.ttf") format("truetype");
    font-style: normal;
}

@font-face {
    font-family: "GeomanistRegular";
    src: url("../assets/fonts/Geomanist-Regular.ttf") format("truetype");
    font-style: normal;
}

@font-face {
    font-family: "GeomanistBold";
    src: url("../assets/fonts/Geomanist-Bold.ttf") format("truetype");
    font-style: normal;
}

@font-face {
    font-family: "GeomanistMedium";
    src: url("../assets/fonts/Geomanist-Medium.ttf") format("truetype");
    font-style: normal;
}

@font-face {
    font-family: "DTGetaiGroteskDisplay";
    src: url("../assets/fonts/DTGetaiGroteskDisplay-Black.otf")
    format("truetype");
    font-style: normal;
}
